body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.statitem {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-style: solid;
  border-radius: 10px;
  border-color: black;
  border-width: 0px;
  background-image: linear-gradient(#61dafb, #0f5e84);
  min-width: 200px;
}

.statcontainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: row wrap;
  margin: 100px 10px 10px 10px;
  padding: 3px;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.mfolder-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: column;
  margin: 10px 10px 10px 10px;
  padding: 3px;
  justify-content: center;
}

.mfolder {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: row wrap;
  margin: 10px 10px 10px 10px;
  padding: 3px;
  justify-content: center;
}

.mfolder-caption {
  color: #33a138;
  font-weight: bold;
  font-family: "Arial Black";
}

.mitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.mitem a {
  color: white;
  text-decoration: none;
}

.mitem-img {
  color: white;
  margin: 10px 10px 0px 0px;
  padding: 5px;
  padding-top: 10px;
  border-style: solid;
  border-radius: 10px;
  border-color: black;
  border-width: 0px;
  background-image: linear-gradient(#61dafb, #0f5e84);
  min-width: 150px;
  min-height: 100px;
  box-shadow: 5px 5px 5px black;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mitem-caption {
  color: white;
  align-items: center;
  margin: 2px;
  padding: 2px;

  font-style: normal;
  text-shadow: 3px 3px 3px black;
}

body {
  background-image: linear-gradient(to right, #4e4e4e, #323232, #323232);
}

.nav {
  background-color: #2f2f2f;
  position: relative;
  overflow: hidden;
}

.nav a {
  float: left;
  color: white;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  font-family: "Helvetica Neue";
}

.nav a:hover{
  float: left;
  color: white;
  background-color: gray;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  font-family: "Helvetica Neue";
}

.nav a active {
  background-color: gray;
  color: white;
}

.nav-right {
  float: right;
}

.folders-editor {
  color: white;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  /*align-items: center;*/
  border: 1px solid #0f5e84;
}

.folder-editor {
  display: flex;
  flex-direction: row;
  border: 1px solid #0f5e84;
  cursor: pointer;
  min-width: 240px;
}

.item-editor {
  margin: 5px;
  border-bottom: 1px solid white;
}

table, th, td {
  border: 1px solid #0f5e84;
  border-collapse: collapse;
}

.f1-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box1 {
  border: 1px solid #0f5e84;
  margin-top: 10px;
}

.box2 {
  border-bottom: 1px solid #0f5e84;
}

@media screen and (max-width: 600px) {
  .nav a, .nav-right {
    float: none;
    display: block;
  }

  .topnav-centered a {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}